(function ($) {

  $(document).ready(function () {

      $('body').bobFormContact(document.bob.contact);

      $('body').scrollToAnchors();

      $('.glyphicons').glyphClone();

      $('.jumbotron a[title^=button], .passage a[title^=button], .paragraph a[title^=button]').mdLinkToButton();

      $('.footer img').mdImageSize();

      $('body').bobButton();

      $('.body-content').linkTap();

      $(window).scrollTopArrow();

      $('.navbar').menuScrollAnimations();

      $('body').fadePage();

      $('.bob-frame-show').bobframe();

      $('.btn').createTrackingClass();

      $('.clipboard').copyUrlToClipboard();

  });

}(jQuery));
