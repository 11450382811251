(function ($) {

    $.fn.fadePage = function () {

        $(this).stop().animate({
            'opacity': 0}, 300, function () {
            $(this).css({
              'visibility': 'visible'
            }).animate({
              'opacity': 1
            }, 300);
          });

    };

}(jQuery));