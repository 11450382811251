(function ($) {

    /**
     * createTrackingClass plugin
     * will create a custom tracking class for each element its executed on
     * can be executed on all buttons for example
     * thereby we will have the same system for tracking classes in all projects
     */
    $.fn.createTrackingClass = function () {

        let page = $('body').attr("class").match(/[\w-]*page[\w-]*/g)[0].replace('age_', '');
        let obj = {};
        let id = 0;

        // $(this) is the jquery object containing all dom objects you want to track
        $.each($(this), function (i, elm) { 
    
            let label = $(elm).text().trim().replace(/[^a-z0-9\s]/gi, '').replaceAll(' ', '_').toLowerCase();
            let createdClass = `track-${page}-${label}`;

            // cause otherwise the classes would be simialr for all forms
            // the form class is put at the and of the created tracking class
            if ($(elm).parents('form').length) {
                createdClass += '-' + $(elm).parents('form').attr("class");
            }
            // in case there are buttons with identical labels
            // to have unique tracking classes
            if (createdClass in obj) {
                id++;
                createdClass += '-' + id;
            }

            $(elm).addClass(createdClass);

            // this will be an object that matches tracking class and dom element
            // will be handy when configuring the actual tracking
            obj[createdClass] = $(elm).get(0);
        });

        // this is so you can easily show all the tracking classes to a marketeer
        // just type document.bob.tclass in the console
        document.bob.tclass = obj;
        
    };

}(jQuery));