(function ($) {

    $.fn.bobButton = function () {

        let timeoutID;

        window.timeoutID = timeoutID;

        var buttonEffect = function (e) {

            e.preventDefault();

            let $targetElement = $(e.target);
            let position = findPos(e.target);
            // console.log($(e.target), position);

            while ($targetElement.is('span')) {
                $targetElement = $targetElement.parent();
                position = findPos($targetElement.get(0))
                // console.log("parent:", $targetElement, position);
            }
            resetButton();
            if ($targetElement.find('span').length == 0) {
                $targetElement.append($('<span></span>'));
            }

            //remove timeout and restart the effect
            // $targetElement.removeClass("feedback");
            window.clearTimeout(timeoutID);
            // console.log(timeoutID);

            var posX = position[0],
                posY = position[1];
            var styles = {
                top: (e.pageY - posY) + 'px',
                left: (e.pageX - posX) + 'px'
            };
            $targetElement.find('span').css(styles);
            $targetElement.addClass("feedback");

            //this finishes of the effect after 1sec. Otherwise the button effect continues after the form is closed.
            timeoutID = setTimeout(resetButton, 1000);

            function resetButton() {
                $targetElement.removeClass("feedback");
                $targetElement.find('span').remove();
                // console.log("time runs out");
            }

        }

        var findPos = function (obj) {

            var curtop = 0;
            var curleft = curtop;

            if (obj.offsetParent) {
                do {
                    curleft += obj.offsetLeft;
                    curtop += obj.offsetTop;
                // eslint-disable-next-line no-cond-assign
                } while (obj = obj.offsetParent);

                return [curleft, curtop];
            }

        }

        $('.btn-effect').on('mousedown touch', function (e) {
            buttonEffect(e);
        });

    };

}(jQuery));