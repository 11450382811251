(function ($) {

    /**
     * custom mixins for lowdash
     */

    /**
     * --- Dates
     */

    let changeFormateDateIso = function (oldDate) {
        // check if it is not iso formatted already
        if (!/^\d{4}/.test(oldDate)) {
            return oldDate.toString().split(".").reverse().join("-");
        }
        return oldDate
    }

    let changeFormateDateUser = function (oldDate) {
        return oldDate.toString().split("-").reverse().join(".");
    }

    let normalizeInput = function (input) {
        // eslint-disable-next-line no-useless-escape
        let reg = /[-\/]/g;

        if (reg.test(input)) {
            return input.replace(reg, '.');
        }
        return input
    }

    /**
     * --- tracking
     */


    /**
     * This creates a custom etracker event
     * TO DO: switch all projects to the new eTracker2 function
     * @param  {string} myObject
     * @param  {string} myCategory
     * @param  {string} myAction
     */
    let eTracker2 = function (myObject, myCategory, myAction) {
        if (typeof (_etracker) === "object") {
            let myType = et_getPageName();
            _etracker.sendEvent(new et_UserDefinedEvent(myObject, myCategory, myAction, myType));
        }
    };
    /**
     * This creates an Order in eTracker
     * Orders are used to measure conversions
     * @param  {string} id of etracker 
     */
    let eTrackerOrder = function (id) {
        if (_.isNil(id)) {
            console.error("please define etracker id");
            return this;
        }
        if (typeof (_etracker) === "object") {
            let name = et_getPageName() + '/conversion';
            let tonr = Date.now();
            et_eC_Wrapper({
                et_et: id,
                et_pagename: name,
                et_tval: "1", //Conversion value
                et_tonr: tonr, //Order number
                et_tsale: 1 //Conversion status sale
            });
        }
    };

    /**
     * --- Events
     */

    /**
     * This simpliefies the triggering an $.Event
     * @param {string} element from which the event is going to start bubbling up
     * @param {string} eName name of what just happend
     * @param {object} bobOptions will be attached to the event
     */
    let throwEvent = function (element, eName, bobOptions = {}) {
        $(element).trigger($.Event(eName, {
            bobOptions,
        }));
    };

    /**
     * --- Session storage
     */

    /**
     * Get a value from session storage
     * @param key
     * @returns {string|undefined}
     */
    let getSessionStorage = function (key) {
        if (_.isString(key)) {
            return sessionStorage.getItem(key);
        } else {
            console.error('getSessionStorage: Given key is not a sting.');
            return undefined;
        }
    };

    /**
     * Set a key/value to session storage
     * @param key
     * @param value
     */
    let setSessionStorage = function (key, value) {
        if (_.isString(key)) {
            if (_.isString(value)) {
                sessionStorage.setItem(key, value);
            } else {
                sessionStorage.setItem(key, JSON.stringify(value));
            }
        } else {
            console.error('getSessionStorage: Given key is not a sting.');
        }
    };

    /**
     * Get a json object from session storage
     * @param key
     * @returns {undefined|any}
     */
    let getJsonSessionStorage = function (key) {
        let value = getSessionStorage(key);
        let jsonValue = JSON.parse(value);
        if (_.isPlainObject(jsonValue)) {
            return jsonValue;
        } else {
            // console.error('getJsonSessionStorage: Key not found or value doesn\'t look like json.');
            // return undefined;
            return {};
        }
    };

    /**
     * Set a json object to session storage
     * @param key
     * @param jsonObject
     */
    let setJsonSessionStorage = function (key, jsonObject) {
        if (_.isPlainObject(jsonObject)) {
            let value = JSON.stringify(jsonObject);
            setSessionStorage(key, value);
        } else {
            console.error('getJsonSessionStorage: Value doesn\'t look like a json object.');
        }
    };

    /**
     * Merge and set a json object with an existing json object from session storage
     * @param key
     * @param jsonObject
     */
    let mergeJsonSessionStorage = function (key, jsonObject) {
        if (_.isPlainObject(jsonObject)) {
            let value = getJsonSessionStorage(key);
            if (_.isPlainObject(value)) {
                setJsonSessionStorage(key, _.merge(value, jsonObject));
            } else {
                setJsonSessionStorage(jsonObject);
            }
        } else {
            console.error('getJsonSessionStorage: Value doesn\'t look like a json object.');
        }
    };

    /**
     * Remove an item from session storage
     * @param key
     */
    let removeSessionStorage = function (key) {
        sessionStorage.removeItem(key);
    };

    /**
     * --- Helpers
     */

    /**
     * Set the focus back to the triggered item
     * This makes sure screen readers have their focus on the element that triggered the bob-frame,
     * when closing the bob-frame. Otherwise the focus would jump to the top of the page.
     * For this to work the triggering button or link needs to have an ID.
     */
    let setFocusToTriggerItem = function () {
        let bobFrameState = getJsonSessionStorage('bob_frame_state');
        let bobMsgState = getJsonSessionStorage('bob_msg_state');
        let triggerItemId = bobFrameState.triggerItem;
        if (triggerItemId && !bobMsgState.msgOpen) {
            $('#' + triggerItemId).trigger('focus');
        }
    };


    /**
     * --- Retrieve Data from forms
     */

    /**
     * gets form data 
     * @param {string} form css selector of a form element
     * @returns {Object} all InputName: "value" combinations
     * @returns {Object} all InputName: "label" combinations
     */
    let getFormData = function (form) {
        let data = {};
        let label = {};
        $(':input', form).each(function () {
            // "this" is the current input element
            if (this.name) {
                data[this.name] = $(this).val();
                label[this.name] = $('label[for=\'' + $(this).attr('id') + '\']', form).text();
            }
        });
        return {
            data,
            label,
        };
    };

    /**
     * gets form data 
     * @param {string} form css selector of a form element
     * @returns {Object} all InputName: "value" combinations
     * @returns {Object} all InputName: "label" combinations
     * @returns {Object} all InputName: "placeholder" combinations
     * @returns {Object} all InputName: "checkboxe" checked status combinations
     */
    let getFormValidationData = function (form) {
        let values = {};
        let labels = {};
        let placeholders = {};
        let checkboxes = {};
        $(':input', form).each(function () {
            // "this" is the current input element
            if (this.name) {
                let exclude = ['sender', 'mailto[]', 'mailto', 'subject', 'submission', 'cc[]', 'cc', 'recipient'];
                if (exclude.indexOf(this.name) < 0) {
                    values[this.name] = {
                        'value': $(this).val(),
                        'element': $(this)
                    };
                } else {
                    values[this.name] = $(this).val();
                }
                labels[this.name] = $('label[for=\'' + $(this).attr('id') + '\']').text();
                placeholders[this.name] = $(this).attr('placeholder') || "";
                if ($(this).attr('type') === 'checkbox') {
                    checkboxes[this.name] = {
                        'status': $(this).is(':checked'),
                        'element': $(this)
                    }
                }
            }
        });
        return {
            values,
            labels,
            placeholders,
            checkboxes,
        };
    };

    /**
     * validate Email
     * be carefull to be in line with backend validations (for example zendesk)
     * @param {string} email user input from the email inputfield
     * @returns {boolean}
     */
    let validateEmail = function (email) {
        // eslint-disable-next-line no-useless-escape
        let re = '^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$';
        let regex = new RegExp(re);
        return regex.test(email);
    };

    /**
     * validate phone
     * @param {string} phone user input from the phone inputfield
     * @returns {boolean}
     */
    let validatePhone = function (phone) {
        var re = /^[0-9\+\ \/\-\.\(\)]{1,20}$/;
        return re.test(phone);
    };

    /**
     * validates the diga token for plausibilty 
     * @param  {} token
     */
    let validateTokenDiga = function (token) {
        let re = /^[A-Z2-7]{16}$/i;
        return re.test(token)
    }

    /**
     * validate kvnr DAK
     * @param {string} kvnr user input from the kvnr inputfield
     * @returns {boolean}
     */
    let validateTokenDak = function (kvnr) {
        var re = /^([a-zA-Z]{1})([0-9]{9})$/i;
        return re.test(kvnr);
    };

    /**
     * validate token Helsana
     * @param {string} input user input from the Helsana token inputfield
     * @returns {boolean}
     */
    let validateTokenHelsana = function (input) {
        var token = input.trim();
        var re = /^[0-9]{8}$/;
        return re.test(token)
    }

    /**
     * simple check if value is in a certain range
     * adds is-invalid to the element if necessary
     * @param  {} elm // jQuery element
     * @param  {} value
     * @param  {} minLength
     * @param  {} maxLength
     */
    let checkLength = function (elm, value, minLength, maxLength) {
        if (value.trim().length < minLength || value.trim().length > maxLength) {
            elm.addClass('is-invalid')
            return false
        } else {
            elm.removeClass('is-invalid')
            return true
        }
    }

    /**
     * submitBtnDisabled
     * changes the disabled property of a submit button
     * @param {string} pre is the prefix of the form derived from its settings  
     * @param {boolean} 
     */
    let submitBtnDisabled = function (pre, bool) {
        $('#' + pre + 'submit-framed').prop('disabled', bool);
    }

    /**
     * --- Add to lodash
     */

    _.mixin({
        'changeFormateDateIso': changeFormateDateIso,
        'changeFormateDateUser': changeFormateDateUser,
        'checkLength': checkLength,
        'eTracker2': eTracker2,
        'eTrackerOrder': eTrackerOrder,
        'getFormData': getFormData,
        'getFormValidationData': getFormValidationData,
        'getJsonSessionStorage': getJsonSessionStorage,
        'getSessionStorage': getSessionStorage,
        'mergeJsonSessionStorage': mergeJsonSessionStorage,
        'normalizeInput': normalizeInput,
        'removeSessionStorage': removeSessionStorage,
        'setFocusToTriggerItem': setFocusToTriggerItem,
        'setJsonSessionStorage': setJsonSessionStorage,
        'setSessionStorage': setSessionStorage,
        'submitBtnDisabled': submitBtnDisabled,
        'throwEvent': throwEvent,
        'validateEmail': validateEmail,
        'validatePhone': validatePhone,
        'validateTokenDak': validateTokenDak,
        'validateTokenDiga': validateTokenDiga,
        'validateTokenHelsana': validateTokenHelsana,
    });

}(jQuery));