(function ($) {


    $.fn.copyUrlToClipboard = function () {
        const $url = $(location).attr('href');
        const $dialog = "<dialog open class='copy-clipboard-dialog'>URL kopiert!</dialog>"

        $('.clipboard').on('click', function() {
            navigator.clipboard.writeText($url).then(
                $('.clipboard').append($dialog)
            ).catch( (err) => {
                console.log('Error: ', err.message);
            });
            setTimeout( () => {
                $('.copy-clipboard-dialog').remove();
            }, 1500)
        })
    }
}(jQuery));
